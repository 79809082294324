import * as React from "react"
import { withPrefix } from "gatsby"
import styled from "styled-components"

const GradientMoviePage = () => {
  return (
    <div className="bg-image vh-100">
      <BackgroundVideo playsInline autoPlay muted loop id="gradient-movie">
        <source className="h-100" src={withPrefix('/images/Purple_Gradient_BG.mp4')} type="video/mp4" />
      </BackgroundVideo>
    </div>
  )
}

const BackgroundVideo = styled.video`
  min-width: 100%; 
  min-height: 100%;
`

export default GradientMoviePage
